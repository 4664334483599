// Imports
// ------
import React from 'react';
import Layout from '@parts/Layout';

// Page
// ------
const NotFoundPage = () => (
	<Layout>
		NOPE!
		{/* TODO • Maybe some sort of video background of a bird looking left to right?!!!?? Or a dear, staring, chewing food?! */}
	</Layout>
);

export default NotFoundPage;
